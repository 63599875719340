import * as React from 'react';
import {useContext, useState} from 'react';
import styled from "styled-components";
import {daysUntilDateFromNowMalaysiaTime} from "../services/daysUntilDateFromNowMalaysiaTime";
import {OrderStatusEnum} from "../enums/OrderStatusEnum";
import {formatDateGMTToMYT} from "../services/formatDateToStringDDMonthYYYY";
import {capitalizeFirstLetter} from "../../../formatters/common/capitalizeFirstLetter";
import {OrderStatusInfoReal} from "../interface/OrderStatusInfo";
import {ReactComponent as BinSVG} from '../../../assets/icons/trashBin.svg'
import {FirstRowContainer} from "../../../commonStyle/FirstRowContainer";
import {DateMsgContainer} from "../../../commonStyle/DateMsgContainer";
import {BoldFXRate} from "../../../commonStyle/BoldFXRate";
import {convertPendingToExpires} from "../services/convertPendingToExpire";
import AlertDialog from "../../../components/common/dialog/AlertDialog";
import {useIntl} from "react-intl";
import {enqueueSnackbar} from "notistack";
import {getIntlObject} from "../../../utils/intl";
import fxAPI from "../../../api/fx.api";
import {RefreshOrderStatusContext} from "./OrderStatus";
import {formatNumberWithThousandSeparator} from "../../../formatters/common/formatNumberWithThousandSeparator";
import {renderRate} from "../../../components/order/CalculationCard";
import {CANCEL_FUTURE_ORDER_FAIL} from "../../../constants/errorMsg.constant";
import {ErrorObjForToastBar} from "../../../types/errorObjType.type";
import {processApiError} from "../../../utils/errorHandling.utils";
import {showMYTText} from "../services/showMYTText";
import {TextWrapper} from "../../../components/styled/TextWrapper";
import {InvalidDateEnum} from "../../../services/InvalidDateEnum";

type Props = {
    order: OrderStatusInfoReal
};

export const OrderCardContent = (props: Props) => {
    const intl = useIntl()
    const [isCancelFutureOrderDialogOpen, setIsCancelFutureOrderDialogOpen] =
        useState<boolean>(false);
    const refreshList = useContext(RefreshOrderStatusContext)

    const openDeleteDialog = (event: React.MouseEvent<HTMLDivElement>) => {
        event.stopPropagation()
        setIsCancelFutureOrderDialogOpen(true)
    }

    const closeDialog = () => {
        setIsCancelFutureOrderDialogOpen(false)
    }

    async function handleCancelFutureOrder() {
        try {
            const response = await fxAPI.cancelFutureOrder(props.order.orderId)
            if (
                !response.data ||
                response.data.responseCode !== "0" ||
                !response.data.data
            ) throw {
                errorMsg: CANCEL_FUTURE_ORDER_FAIL,
                responseHttpStatus: response.status,
                responseData: response.data
            } as ErrorObjForToastBar
            cancelFutureOrderSnackbar()
        } catch (error) {
            processApiError(error, undefined) //this is non-FATAL
        }
        setIsCancelFutureOrderDialogOpen(false)
        setTimeout(refreshList, 2000) //wait two seconds for the server to finish handling
    }

    const isEmptyCancelDate = props.order.orderStatus === OrderStatusEnum.CANCELLED && (props.order.cancelDate === null || props.order.cancelDate === undefined)

    return (
        <>
            <FirstRowContainer>
                <OrderStatusColorizedOval status={props.order.orderStatus}>
                    {capitalizeFirstLetter(props.order.orderStatus)}
                </OrderStatusColorizedOval>
                {
                    props.order.orderStatus === OrderStatusEnum.PENDING &&
                    <div
                        onClick={(event) =>
                            openDeleteDialog(event)
                        }
                        style={{zIndex: 100}}
                    >
                        <BinSVG/>
                    </div>
                }
            </FirstRowContainer>
            <div style={{fontWeight: 600, fontSize: "14px"}}>
                        <span>
                         Convert
                        </span>
                <span>
                            {" "}
                        </span>
                <span style={{color: "#5DBC7D"}}>
                            {getDisplayRate(props.order.symbol, props.order.dealtCurr, props.order.buy, props.order.amount)}
                        </span>
            </div>
            <BoldFXRate>
                {
                    props.order.orderType === 2 &&
                    "when "}
                {renderRate(
                    getCurrencyPart(props.order.symbol, true),
                    getCurrencyPart(props.order.symbol, false),
                    (props.order.rate ? props.order.rate : props.order.limit),
                    props.order.dp,
                    props.order.uq,
                )}
            </BoldFXRate>
            {!isEmptyCancelDate && formatDateGMTToMYT(getDateForCard(props.order), true).date !== InvalidDateEnum.INVALID_DATE &&
                <DateMsgContainer>
                    <TextWrapper>
                        <div>
                            {`${capitalizeFirstLetter(convertPendingToExpires(props.order.orderStatus))} on`}&nbsp;
                        </div>
                        <div>
                            {`${formatDateGMTToMYT(getDateForCard(props.order), true).date},`}&nbsp;
                        </div>
                        <div>
                            {`${formatDateGMTToMYT(getDateForCard(props.order), true).time} ${showMYTText()}`}
                        </div>
                    </TextWrapper>
                    {
                        props.order.orderStatus === OrderStatusEnum.PENDING && (daysUntilDateFromNowMalaysiaTime(
                            props.order.expireDate ? props.order.expireDate as string : props.order.tradeDate as string
                        ) !== 0) &&
                        <div>
                            {daysUntilDateFromNowMalaysiaTime(
                                props.order.expireDate ? props.order.expireDate as string : props.order.tradeDate as string
                            )}
                            {daysUntilDateFromNowMalaysiaTime(
                                props.order.expireDate ? props.order.expireDate as string : props.order.tradeDate as string
                            ) === 1 ? " day" : " days"} left
                        </div>
                    }
                </DateMsgContainer>
            }
            <AlertDialog
                titleComp={intl.formatMessage({
                    id: "app.page.orderStatus.button.title.cancelFutureOrder",
                })}

                contentComp={intl.formatMessage({
                    id: "app.page.orderStatus.button.text.sureToCancelFutureOrder",
                })}
                confirmButtonText={intl.formatMessage({
                    id: "app.page.orderStatus.button.text.confirmDelete",
                })}
                open={isCancelFutureOrderDialogOpen}
                onClose={closeDialog}
                onConfirm={handleCancelFutureOrder}
            />
        </>
    )
};

interface StatusDivProps {
    status: OrderStatusEnum
}

export const OrderStatusColorizedOval = styled.div<StatusDivProps>`
    width: 78px;
    height: 20px;
    padding: 4px;
    border-radius: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    font-family: Montserrat, serif;
    font-size: 10px;
    font-weight: 400;
    line-height: 12px;
    letter-spacing: 0em;
    text-align: center;
    color: white;
    background: ${({status}) => {
        switch (status) {
            case OrderStatusEnum.PENDING:
                return '#FFA227';
            case OrderStatusEnum.COMPLETED:
                return '#67CC89';
            case OrderStatusEnum.UNSUCCESSFUL:
            case OrderStatusEnum.EXPIRED:
                return '#E35D5D';
            case OrderStatusEnum.CANCELLED:
                return '#989899';
            default:
                return 'transparent'; // Default or fallback color
        }
    }};
`;

const getDisplayRate: Function = (symbol: string, dealtCurr: string, buy: boolean, amount: string): string => {
    const nonDealtCurr: string = extractNonOverlap(symbol, dealtCurr)
    const {buyCurr, sellCurr} = determineCurrencies(dealtCurr, nonDealtCurr, buy);
    const formattedAmount = formatNumberWithThousandSeparator(amount)
    return `${sellCurr} ${dealtCurr === sellCurr ? formattedAmount : ""}
     to ${buyCurr} ${dealtCurr === sellCurr ? "" : formattedAmount}`
}

export function determineCurrencies(dealtCurr: string, nonDealtCurr: string, buy: boolean): {
    buyCurr: string,
    sellCurr: string
} {
    let buyCurr: string = buy ? dealtCurr : nonDealtCurr;
    let sellCurr: string = buy ? nonDealtCurr : dealtCurr;
    return {buyCurr, sellCurr};
}

//for pair of AUD and MYR

//if how to know it is buy or sell

// if user sell AUD to MYR, with dealtccy = AUD
// then xxxx AUD to MYR

// if user sell AUD to MYR, with dealtccy = MYR
// the AUD to xxxx MYR

//if user sell MYR to AUD, with dealtccy = AUD
//then MYR to xxx AUD

//if user sell MYR to AUD, with dealthccy = MYR
//then xxx MYR to AUD

//first step, I need to know user buy what and sell what....

//if buy === true, then dealt ccy is buy, and nonDealtCcy is sell

export function extractNonOverlap(param1: string, param2: string): string {
    // Check if param1 includes param2
    if (param1.includes(param2)) {
        // Split param1 at the index of param2 and join the remaining parts
        return param1.replace(param2, '');
    } else {
        // Return 'ERROR' if param2 is not found in param1
        return "ERROR";
    }
}

export function getCurrencyPart(symbol: string, getCcy1: boolean): string {
    if (getCcy1) {
        // Return the first three letters
        return symbol.substr(0, 3);
    } else {
        // Return the last three letters
        return symbol.substr(3, 3);
    }
}

const getDateForCard = (obj: OrderStatusInfoReal): any => {
    switch (obj.orderStatus) {
        case OrderStatusEnum.PENDING:
        case OrderStatusEnum.EXPIRED:
            return obj.expireDate;
        case OrderStatusEnum.COMPLETED:
            return obj.tradeDate;
        case OrderStatusEnum.UNSUCCESSFUL:
        case OrderStatusEnum.CANCELLED:
            return obj.cancelDate ? obj.cancelDate : obj.expireDate;
        default:
            return '';
    }
}

export function cancelFutureOrderSnackbar(): void {
    enqueueSnackbar(
        getIntlObject().formatMessage({
            id: "app.page.orderStatus.button.toastbar.deletedMsg",
        }),
        {variant: "general", mode: "info"}
    );
}

