import {
	ConfirmFXOrderAPIRequestType, ConfirmFXOrderAPIRequestTypeForFuture,
	CreateFXFutureOrderAPIRequestType,
	CreateFXOrderAPIRequestType,
	FXOrderFormType,
} from "../../../../../types/fx.type";
import { isTakerBuyBase } from "../../../order";
import {store} from "../../../../../store/store"
/**
 * Format FX order form data to API request payload.
 * @param {FXOrderFormType} fxOrderForm - The create order form data (ALL PROPERTIES REQUIRED).
 * @return {CreateFXOrderAPIRequestType} The create order API request payload.
 */

function forDateToNumber(input: string): string {
	const date = new Date(input);

	const year = date.getFullYear();
	const month = (date.getMonth() + 1).toString().padStart(2, '0');
	const day = date.getDate().toString().padStart(2, '0');
	const hours = date.getHours().toString().padStart(2, '0');
	const minutes = date.getMinutes().toString().padStart(2, '0');
	const seconds = date.getSeconds().toString().padStart(2, '0');

	return `${year}${month}${day}${hours}${minutes}${seconds}`;
}

const formatCreateOrderPayload = (
	fxOrderForm: FXOrderFormType
): CreateFXOrderAPIRequestType => {
	return {
		type: "rfq",
		symbol: fxOrderForm.fx?.symbol ?? "",
		buy: (typeof fxOrderForm.fx === "undefined" || fxOrderForm.fx === null)
			? false
			:  fxOrderForm.target.currency === fxOrderForm[fxOrderForm.selectingCurrencyType].currency,
		//For above logic, it is, e.g. the user last click CCY2's input yield, so selectingCurreny is equal to targetCurrency
		// then "buy" must be true,

		//lagacy logic
		// : isTakerBuyBase(fxOrderForm.source.currency, fxOrderForm[fxOrderForm.selectingCurrencyType].currency),

		amount: fxOrderForm[fxOrderForm.selectingCurrencyType].amount.value,
		currency: fxOrderForm[fxOrderForm.selectingCurrencyType].currency ?? "",
		fromAccName: fxOrderForm.bankAccount.source?.label ?? "",
		fromAccNo: fxOrderForm.bankAccount.source?.id ?? "",
		toAccName: fxOrderForm.bankAccount.target?.label ?? "",
		toAccNo: fxOrderForm.bankAccount.target?.id ?? "",
		tier: store.getState().user.tier!,
		productType: fxOrderForm.bankAccount.source?.productType!
	};

};

export const formatCreateFutureOrderPayload = (fxOrderForm: FXOrderFormType, payload?: ConfirmFXOrderAPIRequestTypeForFuture): CreateFXFutureOrderAPIRequestType => {
	return {
		type: "limit",
		symbol: fxOrderForm.fx?.symbol ?? "",
		// buy:
		// 	typeof fxOrderForm.fx === undefined || fxOrderForm.fx === null
		// 		? false
		// 		: isTakerBuyBase(fxOrderForm.source.currency, fxOrderForm.fx!
		// 		),
		buy: (typeof fxOrderForm.fx === "undefined" || fxOrderForm.fx === null)
			? false
			:  fxOrderForm.target.currency === fxOrderForm[fxOrderForm.selectingCurrencyType].currency,
		amount: fxOrderForm[fxOrderForm.selectingCurrencyType].amount.value,
		currency: fxOrderForm[fxOrderForm.selectingCurrencyType].currency ?? "",
		fromAccName: fxOrderForm.bankAccount.source?.label ?? "",
		fromAccNo: fxOrderForm.bankAccount.source?.id ?? "",
		toAccName: fxOrderForm.bankAccount.target?.label ?? "",
		toAccNo: fxOrderForm.bankAccount.target?.id ?? "",
		limit: Number(fxOrderForm.futureTargetRate),
		takeProfit: true,
		expire: forDateToNumber(fxOrderForm.expiryDate as string), //todo change it to date
		challengeQuestion: payload?.challengeQuestion,
		challengeAnswer: payload?.challengeAnswer,
		tier: store.getState().user.tier!,
		productType: fxOrderForm.bankAccount.source?.productType!
	};
}

export default formatCreateOrderPayload;
