import React, {useEffect, useRef, useState} from "react";
import {useIntl} from "react-intl";
import styled from "styled-components";
import SubText from "../styled/SubText";
import InputField from "../common/InputField";
import Switch from "../common/Switch";
import FieldLabel from "../styled/FieldLabel";
import FormField from "../styled/FormField";
import FormFieldRow from "../styled/FormFieldRow";
import * as moment from 'moment-timezone';
import $ from 'jquery';

import {useAppDispatch, useAppSelector} from "../../utils/store.utils";
import {
    selectFXOrderForm,
    updateFXOrderForm, updateFXOrderFormExpiryDate,
    updateFXTradeForm,
} from "../../slice/fx";
import {DatePickerDrawer} from "./CalendarFutureOrder/DatePickerDrawer";
import {FutureInputField} from "./FutureOrderInput/FutureInputField/FutureInputField";
import {
    selectApp,
    selectFoDefaultPlusNDate,
    selectRaDefaultPlusNDate,
    setIsWentBackAndToggledFalse
} from "../../slice/app";
import {APP_PATH} from "../../constants/app.constant";
import {isWentBackAndToggledFalseEnum} from "../../types/app.type";

interface FutureOrderSectionProps {
    onRateInputRefChange: (rateInputElement: Element) => void;
    onSetIsNumPadOpen: any;
}

const FutureOrderSwitchFormFieldRow = styled(FormFieldRow)`
    justify-content: space-between;
`;

const FutureOrderLabel = styled.div`
    font-size: 16px;
    font-weight: var(--font-weight-semi-bold);
    line-height: 20px;
`;

const FutureOrderDescription = styled(SubText)`
    margin-top: 4px;
    line-height: 16px;
`;

/**
 * Converts a date string to Malaysia time format.
 *
 * @param {string} dateStr - The date string to be converted.
 * @return {string} The converted date string in Malaysia time format.
 */
export function convertDateStringToMalaysiaTime(dateStr: string): string {
    const date = new Date(dateStr);
    const options: Intl.DateTimeFormatOptions = {
        day: 'numeric',
        month: 'long',
        year: 'numeric',
        timeZone: 'Asia/Kuala_Lumpur'
    };
    return date.toLocaleDateString('en-GB', options);
}

/**
 * Adds a timezone offset to a given date.
 *
 * @param {Date} date - The date to which the timezone offset needs to be added.
 * @param {number} offsetHours - The number of hours to add to the timezone offset.
 * @return {Date} A new Date object with the added timezone offset.
 */
function addTimezoneOffset(date: Date, offsetHours: number) {
    return new Date(date.getTime() + offsetHours * 60 * 60 * 1000);
}

/**
 * Represents a section of a future order form.
 *
 * @typedef {Object} FutureOrderSection
 * @property {Function} onRateInputRefChange - Callback function for the rate input ref change event.
 * @property {boolean} isDatePickerDrawerOpen - Boolean indicating whether the date picker drawer is open or not.
 * @property {Function} setIsDatePickerDrawerOpen - Setter function for the isDatePickerDrawerOpen state value.
 * @property {Object} sectionRef - Ref object for the section div element.
 * @property {Object} app - Application state object.
 * @property {Function} handleFutureSwitchChange - Callback function for the future switch change event.
 * @property {Function} handleDrawerClose - Callback function for closing the date picker drawer.
 * @property {Function} handleDatePicked - Callback function for picking a date from the date picker.
 */
const FutureOrderSection = ({
                                onRateInputRefChange,
                                onSetIsNumPadOpen
                            }: FutureOrderSectionProps) => {
        const intl = useIntl();
        const fxOrderForm = useAppSelector(selectFXOrderForm);
        const dispatch = useAppDispatch();
        const rateInputRef = useRef<HTMLInputElement>(null);
        const [isDatePickerDrawerOpen, setIsDatePickerDrawerOpen] = useState<boolean>(false)
        const sectionRef = useRef<HTMLDivElement>(null);
        const app = useAppSelector(selectApp);
        const foDefaultPlusNDate = useAppSelector(selectFoDefaultPlusNDate)


        useEffect(() => {
            const handleRateInputFocus = () => {
                onRateInputRefChange(rateInputRef.current as Element);
            };

            rateInputRef.current?.addEventListener("focusin", handleRateInputFocus);

            return () => {
                rateInputRef.current?.removeEventListener(
                    "focusin",
                    handleRateInputFocus
                );
            };
        }, [rateInputRef.current]);

        useEffect(() => {
            let isTouchInsideInput = false;

            const handleTouchStart = (event: TouchEvent) => {
                // console.log("touchStart")
                if (rateInputRef.current && rateInputRef.current.contains(event.target as Node)) {
                    isTouchInsideInput = true; // Touch started inside the input field
                } else {
                    isTouchInsideInput = false; // Touch started outside the input field
                    rateInputRef.current?.blur(); // Blur the input to hide the numpad
                }
            };

            const handleTouchMove = (event: TouchEvent) => {
                // console.log("touchMove")
                // console.log('this should blur')
                rateInputRef.current?.blur(); // Blur the input if touch moves outside the input
            };

            document.addEventListener('touchstart', handleTouchStart, {passive: true});
            document.addEventListener('touchmove', handleTouchMove, {passive: true});

            return () => {
                document.removeEventListener('touchstart', handleTouchStart);
                document.removeEventListener('touchmove', handleTouchMove);
            };
        }, []);

        const handleFutureSwitchChange = (
            event: React.ChangeEvent<HTMLInputElement>
        ) => {
            if (!event.currentTarget.checked) {
                const isComeBackFromStep2: boolean = app.backFromPath.path.includes(APP_PATH.fx.order.account)
                const isComeBackFromConfirmation: boolean = app.backFromPath.path.includes(APP_PATH.fx.order.confirmation)
                if (isComeBackFromStep2 || isComeBackFromConfirmation) {
                    dispatch(
                        updateFXTradeForm({
                            form: "order",
                            data: {...fxOrderForm, future: event.currentTarget.checked, futureTargetRate: ""},
                        })
                    );
                    dispatch(
                        setIsWentBackAndToggledFalse(isWentBackAndToggledFalseEnum.WENT_BACK_AND_TOGGLE_FALSE)
                    )
                } else {
                    dispatch(
                        updateFXTradeForm({
                            form: "order",
                            data: {...fxOrderForm, future: event.currentTarget.checked, futureTargetRate: ""},
                        }),
                    );
                }
            } else {
                dispatch(
                    updateFXTradeForm({
                        form: "order",
                        data: {...fxOrderForm, future: event.currentTarget.checked},
                    })
                );
                const dateInMY = moment.tz("Asia/Kuala_Lumpur");
                dateInMY.add(foDefaultPlusNDate, 'days');
                dispatch(
                    updateFXOrderFormExpiryDate(dateInMY.format())
                )
                setTimeout(() => {
                    if (sectionRef.current) {
                        sectionRef.current.scrollIntoView({behavior: 'smooth'});
                    }
                }, 100);
            }
            // console.log('updateFXTradeForm  from FutureOrderFormFields 1:handleFutureSwitchChange')
        };

        const handleDrawerClose = () => {
            setIsDatePickerDrawerOpen(false)
        }

        const handleDatePicked = (expiryDate: Date) => {
            // console.log(expiryDate.toDateString())
            dispatch(
                updateFXOrderFormExpiryDate(expiryDate.toDateString())
            )
        }

        // @ts-ignore
        return (
            <>
                <FormField>
                    <FutureOrderSwitchFormFieldRow>
                        <FutureOrderLabel>
                            {intl.formatMessage({
                                id: "app.page.orderForm.form.order.label.futureOrder",
                            })}
                            <FutureOrderDescription>
                                {intl.formatMessage({
                                    id: "app.page.orderForm.form.order.field.expiration.message.description",
                                })}
                            </FutureOrderDescription>
                        </FutureOrderLabel>
                        <Switch
                            id={"future-toggle-switch"}
                            checked={fxOrderForm.future}
                            onChange={handleFutureSwitchChange}
                        />
                    </FutureOrderSwitchFormFieldRow>
                </FormField>
                {fxOrderForm.future && (
                    <div ref={sectionRef}>
                        <FormField>
                            <FieldLabel hasMarginBottom>
                                {intl.formatMessage({
                                    id: "app.page.orderForm.form.order.label.limitRate",
                                })}
                            </FieldLabel>
                            <div>
                                <FutureInputField
                                    fullWidth
                                    inputProps={{
                                        type: "text",
                                        ref: rateInputRef,
                                        readOnly: false,
                                        onClick: () => {
                                            onSetIsNumPadOpen(false);
                                            const numpadMain = document.querySelector('.numpad-main');
                                            rateInputRef.current!.scrollIntoView({behavior: 'auto',});
                                            setTimeout(() => {
                                                rateInputRef.current!.scrollIntoView({behavior: 'auto',});
                                            }, 200)
                                            if (numpadMain) {
                                                // @ts-ignore
                                                numpadMain.style.setProperty('display', 'none', 'important');
                                            } else {
                                                console.log('Element not found');
                                            }
                                        },
                                    }}
                                    helperText={fxOrderForm.source.amount.helper?.text}
                                    helperTextProps={{
                                        type: fxOrderForm.source.amount.helper?.type,
                                    }}
                                    fxDataForm={fxOrderForm}
                                />
                            </div>
                        </FormField>
                        <FormField>
                            <FieldLabel hasMarginBottom>
                                {intl.formatMessage({
                                    id: "app.page.orderForm.form.order.label.expiration",
                                })}
                            </FieldLabel>
                            <div
                                onClick={() => {
                                    setIsDatePickerDrawerOpen(true)
                                }}
                            >
                                <InputField
                                    id={"future_date_field"}
                                    value={fxOrderForm.expiryDate as string ?
                                        convertDateStringToMalaysiaTime(fxOrderForm.expiryDate as string)
                                        :
                                        "Click to select date"}
                                    fullWidth
                                    inputProps={{
                                        type: "text",
                                        readOnly: true,
                                    }}
                                    helperText={intl.formatMessage({
                                        id: "app.page.orderForm.form.order.field.expiration.message.instruction",
                                    })}
                                />
                            </div>
                        </FormField>
                        <SubText>
                            {intl.formatMessage({
                                id: "app.page.orderForm.message.reminder.content",
                            })}
                        </SubText>
                        <DatePickerDrawer
                            open={isDatePickerDrawerOpen}
                            onClose={handleDrawerClose}
                            onPick={handleDatePicked}
                        />
                    </div>
                )
                }
            </>
        )
            ;
    }
;

export default FutureOrderSection;
