import * as React from 'react';
import {useEffect, useRef} from "react";
import CurrencySelectorDialog from "../../components/common/dialog/CurrencySelectorDialog";
import {FX_TRADE_CURRENCY_TYPE} from "../../constants/fx.constant";
import {useAppDispatch, useAppSelector} from "../../utils/store.utils";
import {
    selectCurrencies,
    selectFXList,
    selectFXRateAlert, updateRateAlertExpiryDate,
    updateRateAlertFrom,
    updateRateAlertTo
} from "../../slice/fx";
import useFXCalculator from "../../hooks/useFXCalculator";
import {CurrencyType} from "../../types/fx.type";
import {formatURL} from "../../formatters/common";
import useNavigateThrottle from "../../hooks/useNavigateThrottle";
import {fetchRate} from "../order/services/fetchRate";
import {Helmet} from "react-helmet";
import {findExchangeableCurrenciesInFXList} from "../../formatters/fx";
import * as moment from "moment-timezone";
import {useLocation} from "react-router-dom";
import {selectRaDefaultPlusNDate} from "../../slice/app";

type Props = {
    notPage?: boolean
    onOpen?: any
};
export const CreateRateAlertStepTwo = (props: Props) => {
    const dispatch = useAppDispatch();
    const navigate = useNavigateThrottle();
    const currencies = useAppSelector(selectCurrencies);
    const rateAlertCcy = useAppSelector(selectFXRateAlert)
    const sourceCurrencyInputRef = useRef<HTMLInputElement>(null);
    const targetCurrencyInputRef = useRef<HTMLInputElement>(null);
    const fxList = useAppSelector(selectFXList);
    const raDefaultPlusNDate = useAppSelector(selectRaDefaultPlusNDate)
    const {
        focusingCurrencySelector,
        availableCurrencies,
        isCurrencySelectorDialogOpen,
        handleCurrencySelectorOpen,
        handleCurrencySelectorClose,
    } = useFXCalculator({
        form: "calculator",
        targetInputElement: targetCurrencyInputRef.current,
    });

    useEffect(() => {


        handleCurrencySelectorOpen(FX_TRADE_CURRENCY_TYPE.TARGET, currencies)
        //overall, run a function that open the component

        //if from rateAlert/view,
        //  1. clear all previous record

        // if from step-3
        // 1. don't clear anything
        // 2. have a useState that store if it is from step-3 (is that necessary? should I put the logic in the click?)

    }, []);

    //what I need to do:
    // copy the logic from createRateAlert to here

    // const openFromCurrencyDialog = () =>{
    //     handleCurrencySelectorOpen(FX_TRADE_CURRENCY_TYPE.SOURCE, currencies);
    // }

    const location = useLocation();
    const isURLStep2 = location.pathname.includes("/rateAlert/create/step2");

    useEffect(() => {
        if(isURLStep2){
            const dateInMY = moment.tz("Asia/Kuala_Lumpur");
            dateInMY.add(raDefaultPlusNDate, 'days');
            dispatch(
                updateRateAlertExpiryDate(dateInMY.format())
            )
        }
    }, []);

    const handleEditToCcy = (currencyObject: CurrencyType) => {
        switch (focusingCurrencySelector) {
            case FX_TRADE_CURRENCY_TYPE.SOURCE:
                dispatch(updateRateAlertFrom({
                    currency: currencyObject.currency,
                    name: currencyObject.name
                }));
                break
            case FX_TRADE_CURRENCY_TYPE.TARGET:
                dispatch(updateRateAlertTo({
                    currency: currencyObject.currency,
                    name: currencyObject.name
                }));
                break
        }
        if (props.notPage && props.onOpen) {
            props.onOpen(false)
        } else {
            navigate(formatURL("/rateAlert/create/step3"))

        }
    }

    const omitCcySelectedFromStep1 = (fromCcy: string | undefined, ccyList: CurrencyType[]): CurrencyType[] => {
        if (!fromCcy) return []
        // const omitted = ccyList.filter(item => item.currency !== fromCcy);
        return findExchangeableCurrenciesInFXList(
            fxList,
            ccyList,
            fromCcy
        )
    }

    return (
        <>
            <Helmet>
                <title>
                    Create Rate Alert Step-2
                </title>
            </Helmet>
            <CurrencySelectorDialog
                open={isCurrencySelectorDialogOpen}
                availableCurrencies={
                    omitCcySelectedFromStep1(rateAlertCcy.source.currency, availableCurrencies)
                }
                onClose={() => {
                    handleCurrencySelectorClose()
                    if(props.notPage){
                        props.onOpen(false)
                    }
                }}
                onConfirm={handleEditToCcy}
                direction={"to"}
                leftIconSpecFunc={props.notPage ? undefined:"back"}

            />
        </>
    );
};
