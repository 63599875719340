import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {RootState} from "../store/store";

import {AppHistoryType, AppType, isWentBackAndToggledFalseEnum} from "../types/app.type";
import {APP_NAVIGATE_DIRECTION} from "../constants/app.constant";

const initialState: AppType = {
    title: "",
    navigateDirection: APP_NAVIGATE_DIRECTION.NEXT,
    history: [],
    isRootScrollable: true,
    debugMode: false,
    backFromPath: {path: ""},
    isWentBackAndToggledFalse: isWentBackAndToggledFalseEnum.FIRST_OPEN_PAGE,
    latestErrorMsg: "",
    triggerCloseApp: "",
    isAfterPressingCancelOrder: false,
    ra_max_active_count: 10, // Maximum Number of Active Rate Alerts
    ra_max_expiry_date: 29, // Maximum Number of Days of Validity of Rate Alerts
    ra_default_plus_n_date:29,
    ra_calendar_max_previous_month: 2, // Maximum number of previous month calendar for rate alert
    ra_calendar_max_future_month:3,
    fo_max_active_count: 10, // Maximum Number of Active Future Orders (not yet implemented)
    fo_max_expiry_date: 29, // Maximum Number of Days of Validity of Future Orders
    fo_default_plus_n_date:1,
    fo_calendar_max_previous_month: 2, // Maximum number of previous month calendar for future order
    fo_calendar_max_future_month: 3, // Maximum number of future month calendar for future order
    wl_update_interval: 3000, // Watchlist update interval (3 seconds now)
    ui_post_msg_wait_time: 120000, // post_message waiting MBB's app response time (120 seconds)
    news_update_interval: 15000, // news update interval (15 seconds now)
    fo_profit_limitation: 0.1, // FO's 10% profit limitation on future rate input box
    ra_retry:0,
    fo_retry:0,
};

export const appSlice = createSlice({
    name: "app",
    initialState,
    // The `reducers` field lets us define reducers and generate associated actions
    reducers: {
        updateAppTitle: (state, action: PayloadAction<string>) => {
            state.title = action.payload;
        },
        setNavigateDirection: (
            state,
            action: PayloadAction<APP_NAVIGATE_DIRECTION>
        ) => {
            state.navigateDirection = action.payload;
        },
        pushHistory: (state, action: PayloadAction<AppHistoryType>) => {
            state.history.push(action.payload);
        },
        popHistory: (state, action: PayloadAction<number>) => {
            if (action.payload < 0 && isFinite(action.payload))
                state.history = state.history.slice(0, Math.trunc(action.payload));
        },
        replaceHistory: (state, action: PayloadAction<AppHistoryType>) => {
            if (state.history.length > 0)
                state.history[state.history.length - 1] = action.payload;
        },
        sliceHistoryToPoint: (state, action: PayloadAction<string>) => {
            function truncateArrayBeforePath(objects: { path: string }[], searchString: string): { path: string }[] {
                // console.log("searchString", searchString)
                const index = objects.findIndex(obj => obj.path.includes(searchString));
                if (index !== -1) {
                    return objects.slice(0, index+1);
                }
                // console.log("index", index)
                // console.log("objects", objects)
                return objects;
            }
            state.history = truncateArrayBeforePath(state.history, action.payload)
        },

        setIsRootScrollable: (state, action: PayloadAction<boolean>) => {
            state.isRootScrollable = action.payload;
        },
        setDebugMode: (state, action: PayloadAction<boolean>) => {
            state.debugMode = action.payload;
        },
        setBackFromPath: (state, action: PayloadAction<AppHistoryType>): void => {
            state.backFromPath = action.payload
        },
        setIsWentBackAndToggledFalse: (state, action: PayloadAction<isWentBackAndToggledFalseEnum>): void => {
            state.isWentBackAndToggledFalse = action.payload
        },
        setLatestErrorMsg: (state, action: PayloadAction<string>): void => {
            state.latestErrorMsg = action.payload
        },
        updateTriggerCloseApp: (state): void => {
            state.triggerCloseApp = "triggered" + Math.random()
        },
        updateIsAfterPressingCancelOrder: (state, action: PayloadAction<boolean>): void => {
            state.isAfterPressingCancelOrder = action.payload
        },
        setRaMaxActiveCount: (state, action: PayloadAction<number>) => {
            state.ra_max_active_count = action.payload;
        },
        setRaMaxExpiryDate: (state, action: PayloadAction<number>) => {
            state.ra_max_expiry_date = action.payload;
        },
        setFoMaxActiveCount: (state, action: PayloadAction<number>) => {
            state.fo_max_active_count = action.payload;
        },
        setFoMaxExpiryDate: (state, action: PayloadAction<number>) => {
            state.fo_max_expiry_date = action.payload;
        },
        setWlUpdateInterval: (state, action: PayloadAction<number>) => {
            state.wl_update_interval = action.payload;
        },
        setUiPostMsgWaitTime: (state, action: PayloadAction<number>) => {
            state.ui_post_msg_wait_time = action.payload;
        },
        setNewsUpdateInterval: (state, action: PayloadAction<number>) => {
            state.news_update_interval = action.payload;
        },
        setFoProfitLimitation: (state, action: PayloadAction<number>) => {
            state.fo_profit_limitation = action.payload;
        },
        setFoRetry: (state, action: PayloadAction<number>) => {
            state.fo_retry = action.payload;
        },
        setRaRetry: (state, action: PayloadAction<number>) => {
            state.ra_retry = action.payload;
        },
        setRaDefaultPlusNDate: (state, action: PayloadAction<number>) => {
            state.ra_default_plus_n_date = action.payload;
        },
        setRaCalendarMaxPreviousMonth: (state, action: PayloadAction<number>) => {
            state.ra_calendar_max_previous_month = action.payload;
        },
        setRaCalendarMaxFutureMonth: (state, action: PayloadAction<number>) => {
            state.ra_calendar_max_future_month = action.payload;
        },
        setFoDefaultPlusNDate: (state, action: PayloadAction<number>) => {
            state.fo_default_plus_n_date = action.payload;
        },
        setFoCalendarMaxPreviousMonth: (state, action: PayloadAction<number>) => {
            state.fo_calendar_max_previous_month = action.payload;
        },
        setFoCalendarMaxFutureMonth: (state, action: PayloadAction<number>) => {
            state.fo_calendar_max_future_month = action.payload;
        }
    }

});

export const {
    updateAppTitle,
    setNavigateDirection,
    pushHistory,
    popHistory,
    replaceHistory,
    setIsRootScrollable,
    setDebugMode,
    setBackFromPath,
    setIsWentBackAndToggledFalse,
    setLatestErrorMsg,
    updateTriggerCloseApp,
    updateIsAfterPressingCancelOrder,
    sliceHistoryToPoint,
    setRaMaxActiveCount,
    setRaMaxExpiryDate,
    setFoMaxActiveCount,
    setFoMaxExpiryDate,
    setWlUpdateInterval,
    setUiPostMsgWaitTime,
    setNewsUpdateInterval,
    setFoProfitLimitation,
    setFoRetry,
    setRaRetry,
    setRaDefaultPlusNDate,
    setRaCalendarMaxPreviousMonth,
    setRaCalendarMaxFutureMonth,
    setFoDefaultPlusNDate,
    setFoCalendarMaxPreviousMonth,
    setFoCalendarMaxFutureMonth
} = appSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`

export const selectApp = (state: RootState) => state.app;
export const selectAppHistory = (state: RootState) => state.app.history
export const selectAppHistoryPrevPagePath = (state: RootState) => {
    if (state.app.history.length - 2 < 0) {
        return ""
    }
    return state.app.history[state.app.history.length - 2].path
}

export const selectAppBackFromPath = (state: RootState) => state.app.backFromPath.path;

export const selectAppTriggerCloseApp = (state: RootState) => state.app.triggerCloseApp

export const selectIsAfterPressingCancelOrder = (state: RootState) =>state.app.isAfterPressingCancelOrder

export const selectRaMaxActiveCount = (state: RootState) => state.app.ra_max_active_count;
export const selectRaMaxExpiryDate = (state: RootState) => state.app.ra_max_expiry_date;
export const selectFoMaxActiveCount = (state: RootState) => state.app.fo_max_active_count;
export const selectFoMaxExpiryDate = (state: RootState) => state.app.fo_max_expiry_date;
export const selectWlUpdateInterval = (state: RootState) => state.app.wl_update_interval;
export const selectUiPostMsgWaitTime = (state: RootState) => state.app.ui_post_msg_wait_time;
export const selectNewsUpdateInterval = (state: RootState) => state.app.news_update_interval;
export const selectFoProfitLimitation = (state: RootState) => state.app.fo_profit_limitation;

export const selectFoRetry = (state: RootState) => state.app.fo_retry
export const selectRaRetry = (state: RootState) => state.app.ra_retry

export const selectRaDefaultPlusNDate = (state: RootState) => state.app.ra_default_plus_n_date
export const selectRaCalendarMaxPreviousMonth = (state: RootState) => state.app.ra_calendar_max_previous_month
export const selectRaCalendarMaxFutureMonth = (state: RootState) => state.app.ra_calendar_max_future_month
export const selectFoDefaultPlusNDate = (state: RootState) => state.app.fo_default_plus_n_date
export const selectFoCalendarMaxPreviousMonth = (state: RootState) => state.app.fo_calendar_max_previous_month
export const selectFoCalendarMaxFutureMonth = (state: RootState) => state.app.fo_calendar_max_future_month






// We can also write thunks by hand, which may contain both sync and async logic.
// Here's an example of conditionally dispatching actions based on current state.
// export const incrementIfOdd =
//   (amount: number): AppThunk =>
//   (dispatch, getState) => {
//     const currentValue = selectCount(getState());
//     if (currentValue % 2 === 1) {
//       dispatch(incrementByAmount(amount));
//     }
//   };
export default appSlice.reducer;
