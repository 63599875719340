import React, { useEffect, useState } from "react";
import { IntlProvider } from "react-intl";

// Localization
import {
	DEFAULT_LOCALIZATION,
	DEFAULT_LANGUAGE,
	LOCALE_LOCAL_STORAGE_KEY,
} from "../constants/common.constant";
import { getLocalStorage, setLocalStorage } from "../utils/common.utils";

export type LocalizationContextType = {
	locale: keyof typeof DEFAULT_LOCALIZATION;
	changeLanguage: (selected: keyof typeof DEFAULT_LOCALIZATION) => void;
};

export const LocalizationContext = React.createContext<LocalizationContextType>(
	{
		locale: DEFAULT_LANGUAGE,
		changeLanguage: () => {},
	}
);

const LocalizationContextProvider = ({
	children,
}: {
	children: React.ReactNode;
}) => {
	const localStorageLocale = getLocalStorage<string>(
		LOCALE_LOCAL_STORAGE_KEY,
		false
	);
	const [locale, setLocale] = useState<keyof typeof DEFAULT_LOCALIZATION>(
		(localStorageLocale as keyof typeof DEFAULT_LOCALIZATION) ||
			DEFAULT_LANGUAGE
	);
	const [messages, setMessages] = useState(
		DEFAULT_LOCALIZATION[locale as keyof typeof DEFAULT_LOCALIZATION]
	);

	useEffect(() => {
		if (!localStorageLocale) {
			// console.log("[Initialization] set local storage - locale");
			setLocalStorage(LOCALE_LOCAL_STORAGE_KEY, locale);
		}
	}, []);

	const changeLanguage = (selected: keyof typeof DEFAULT_LOCALIZATION) => {
		setLocalStorage(LOCALE_LOCAL_STORAGE_KEY, selected);
		setLocale(selected);
		setMessages(DEFAULT_LOCALIZATION[selected]);
	};

	return (
		<LocalizationContext.Provider value={{ locale, changeLanguage }}>
			<IntlProvider
				locale={locale}
				defaultLocale={DEFAULT_LANGUAGE}
				messages={messages}
			>
				{children}
			</IntlProvider>
		</LocalizationContext.Provider>
	);
};

export default LocalizationContextProvider;
