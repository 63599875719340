import * as React from 'react';
import {useEffect, useRef} from "react";
import CurrencySelectorDialog from "../../components/common/dialog/CurrencySelectorDialog";
import {FX_TRADE_CURRENCY_TYPE} from "../../constants/fx.constant";
import {useAppDispatch, useAppSelector} from "../../utils/store.utils";
import {
    resetRateAlertTo,
    selectCurrencies, selectFXList,
    selectFXRateAlert, selectLastOrderStatusTab, updateRateAlertExpiryDate,
    updateRateAlertFrom,
    updateRateAlertTo
} from "../../slice/fx";
import useFXCalculator from "../../hooks/useFXCalculator";
import {CurrencyType} from "../../types/fx.type";
import {formatURL} from "../../formatters/common";
import useNavigateThrottle from "../../hooks/useNavigateThrottle";
import {selectAppHistoryPrevPagePath, selectRaDefaultPlusNDate} from "../../slice/app";
import {Helmet} from "react-helmet";
import {findExchangeableCurrenciesInFXList} from "../../formatters/fx";
import * as moment from "moment-timezone";
import { useLocation } from 'react-router-dom';


type Props = {
    notPage?: boolean
    onOpen?: any
};
export const CreateRateAlertStepOne = (props: Props) => {
    const dispatch = useAppDispatch();
    const navigate = useNavigateThrottle();
    const currencies = useAppSelector(selectCurrencies);
    const prevPagePath = useAppSelector(selectAppHistoryPrevPagePath)
    const rateAlertCcy = useAppSelector(selectFXRateAlert)
    const sourceCurrencyInputRef = useRef<HTMLInputElement>(null);
    const targetCurrencyInputRef = useRef<HTMLInputElement>(null);
    const {
        focusingCurrencySelector,
        availableCurrencies,
        isCurrencySelectorDialogOpen,
        handleCurrencySelectorOpen,
        handleCurrencySelectorClose,
    } = useFXCalculator({
        form: "calculator",
        targetInputElement: targetCurrencyInputRef.current,
    });
    const fxList = useAppSelector(selectFXList);
    const fxRateAlert = useAppSelector(selectFXRateAlert)
    const raDefaultPlusNDate = useAppSelector(selectRaDefaultPlusNDate)


    useEffect(() => {
        handleCurrencySelectorOpen(FX_TRADE_CURRENCY_TYPE.SOURCE, currencies)
    }, []);

    const location = useLocation();
    const isURLStep1 = location.pathname.includes("/rateAlert/create/step1");

    useEffect(() => {
        if(isURLStep1){
            const dateInMY = moment.tz("Asia/Kuala_Lumpur");
            dateInMY.add(raDefaultPlusNDate, 'days');
            dispatch(
                updateRateAlertExpiryDate(dateInMY.format())
            )
        }
    }, []);

    const handleEditFromCcy = (currencyObject: CurrencyType) => {
        switch (focusingCurrencySelector) {
            case FX_TRADE_CURRENCY_TYPE.SOURCE:
                dispatch(updateRateAlertFrom({
                    currency: currencyObject.currency,
                    name: currencyObject.name
                }));
                if (rateAlertCcy.target.currency === currencyObject.currency) {
                    dispatch(resetRateAlertTo())
                }

                if(!findExchangeableCurrenciesInFXList(
                    fxList,
                    currencies,
                    currencyObject.currency
                ).find((item) => item.currency === rateAlertCcy.target.currency)){
                    dispatch(resetRateAlertTo())
                }
                break
            case FX_TRADE_CURRENCY_TYPE.TARGET:
                dispatch(updateRateAlertTo({
                    currency: currencyObject.currency,
                    name: currencyObject.name
                }));
                break
        }
        if (props.notPage && props.onOpen) {
            props.onOpen(false)
        } else {
            if (prevPagePath.includes("create/step3")) {
                navigate(formatURL("/rateAlert/create/step3"))
            } else {
                navigate(formatURL("/rateAlert/create/step2"))
            }
        }

    }
    return (
        <>
            <Helmet>
                <title>
                    Create Rate Alert Step-1
                </title>
            </Helmet>
            <CurrencySelectorDialog
                open={isCurrencySelectorDialogOpen}
                availableCurrencies={availableCurrencies}
                onClose={() => {
                    handleCurrencySelectorClose()
                    if(props.notPage){
                        props.onOpen(false)
                    }
                }
                }
                onConfirm={handleEditFromCcy}
                direction={"from"}
                leftIconSpecFunc={props.notPage ? undefined:"back"}
            />
        </>
    );
};
