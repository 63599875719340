import {useEffect, useRef, useState} from "react";
import styled from "styled-components";
import {useIntl} from "react-intl";
import moment from "moment";

import Layout from "../../components/common/Layout";
import MaeLoading from "../../components/common/MaeLoading";

import {
    ConfirmFXOrderAPIRequestType,
    ConfirmFXOrderAPIRequestTypeForFuture,
    CreateFXOrderAPIResponseType,
    FXOrderFormRequiredType,
    FXOrderRSAType,
} from "../../types/fx.type";
import {IntegrationMessageDataType} from "../../types/integration.type";
import {
    FX_ORDER_CONFIRM_STATUS_CODE,
    FX_ORDER_TRANSACTION_ACKNOWLEDGEMENT_STATUS_CODE,
    FX_ORDER_TRANSACTION_ACKNOWLEDGEMENT_STATUS_TYPE, POST_MESSAGE_ACKE_WAITING_TIME,
} from "../../constants/fx.constant";
import {MAE_APP_RESPONSE_EVENT_TYPE} from "../../constants/integration.constant";
import {getURLQueryValueByKey} from "../../utils/common.utils";
import {useAppDispatch, useAppSelector} from "../../utils/store.utils";
import {
    clearPasswordTimeoutInterval, clearRsaTimeoutInterval,
    emitErrorToApp, mockRequestPasswordAuthentication, navigateToApp,
    openTransactionResultView,
    requestPasswordAuthentication,
    requestRSA,
} from "../../utils/integration.utils";
import {selectApp, selectUiPostMsgWaitTime} from "../../slice/app";
import {
    resetFXOrderForm,
    selectCurrencies, selectCurrentOrPrevOrderId,
    selectFXOrderForm,
} from "../../slice/fx";
import useNavigateThrottle from "../../hooks/useNavigateThrottle";
import {validateOrderForm} from "../../formatters/fx/order";
import {formatConfirmFXOrder} from "../../formatters/fx/api/response";
import fxAPI from "../../api/fx.api";

import topBackground from "../../assets/images/transaction-loading-top-background.svg";
import bottomBackground from "../../assets/images/transaction-loading-bottom-background.svg";
import {
    formatCreateFutureOrderPayload
} from "../../formatters/fx/api/request/formatCreateFXOrderPayload/formatCreateFXOrderPayload";
import {
    formatCreateFutureOrder
} from "../../formatters/fx/api/response/formatCreateFutureOrder/formatCreateFutureOrder";
import {AxiosResponse} from "axios";
import {
    CloseReason,
    useSnackbar
} from "notistack";
import {processApiError} from "../../utils/errorHandling.utils";
import {
    FUTURE_ORDER_CREATE_ORDER_FAIL,
    MARKET_ORDER_CONFIRM_ORDER_FAIL,
    REJECT_QUOTE_REJECT_BY_UI
} from "../../constants/errorMsg.constant";
import {ErrorObjForToastBar} from "../../types/errorObjType.type";
import {updateAPIAxiosInstanceToken} from "../../utils/api.utils";


const Background = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    background-image: url(${topBackground}), url(${bottomBackground});
    background-repeat: no-repeat, no-repeat;
    background-position: top, bottom;
    background-size: contain, contain;
    overflow: hidden;
`;

const LoadingContainer = styled.div`
    position: absolute;
    top: calc(50% - 48px);
    left: 0;
    right: 0;
    transform: translate(0, -50%);
`;

const LoadingMessage = styled.div`
    margin-top: 35px;
    padding-left: var(--common-layout-padding-x);
    padding-right: var(--common-layout-padding-x);
    font-size: 20px;
    font-weight: var(--font-weight-semi-light);
    text-align: center;
    line-height: 26px;
`;

const OrderPlacePage = () => {
    const navigate = useNavigateThrottle();
    const orderId = getURLQueryValueByKey("id") as string;
    const intl = useIntl();
    const app = useAppSelector(selectApp);
    const currencies = useAppSelector(selectCurrencies);
    const fxOrderForm = useAppSelector(selectFXOrderForm);
    const errorTimeout = useAppSelector((state) => state.errorTimeout);
    const dispatch = useAppDispatch();
    const [challengeQuestion, setChallengeQuestion] = useState<string>("");
    const {enqueueSnackbar, closeSnackbar} = useSnackbar()
    const isAlreadyTimeout = useRef<boolean>(false)
    const currentOrPrevOrderId = useAppSelector(selectCurrentOrPrevOrderId)
    const postMessageAckWaitingTime = useAppSelector(selectUiPostMsgWaitTime)

    useEffect(() => {
        // Password authentication
        requestPasswordAuthentication();
        // mockRequestPasswordAuthentication()
    }, []);

    useEffect(() => {
        // MAE app post message event
        const handleAuthenticate = (message: MessageEvent<string>) => {
            // console.log("message:", message)
            try {
                const formattedData: IntegrationMessageDataType = JSON.parse(
                    message.data
                );
                switch (formattedData.type) {
                    case MAE_APP_RESPONSE_EVENT_TYPE.PASSWORD:
                        if (errorTimeout.passwordResponseRemainTime > 0 && !isAlreadyTimeout.current){
                            const token = formattedData.data //this is L3 token, according to the DRS
                            if(token){
                                updateAPIAxiosInstanceToken(token)
                            }
                            placeOrder();
                            clearPasswordTimeoutInterval()
                            closeSnackbar()
                        }
                        break;
                    case MAE_APP_RESPONSE_EVENT_TYPE.RSA:
                        if (errorTimeout.rsaResponseRemainTime > 0 && !isAlreadyTimeout.current) {
                                placeOrder({
                                    orderId,
                                    challengeQuestion,
                                    challengeAnswer: formattedData.data,
                                });
                                clearRsaTimeoutInterval()
                                closeSnackbar()
                        }
                        break;
                }
            } catch (error) {
                console.log(error);
            }
        };

        const onMessageHandler: EventListener = (event: any) => {
            if (
                event.type &&
                event.type === "message" &&
                event.data &&
                typeof event.data === "string"
            ) {
                handleAuthenticate(event);
            }
        };
        window.addEventListener("message", handleAuthenticate); // iOS
        document.addEventListener("message", onMessageHandler); // Android

        return () => {
            window.removeEventListener("message", handleAuthenticate); // iOS
            document.removeEventListener("message", onMessageHandler); // Android
        };
    }, [challengeQuestion]);

    /**
     * This function is used to display a snackbar notification if there is no acknowledgement response within a specified time.
     * The snackbar notification is persistent, meaning it will not automatically disappear until manually closed.
     * The time to wait for the acknowledgement response is defined by the constant POST_MESSAGE_ACK_WATING_TIME.
     */
    if (errorTimeout.passwordResponseRemainTime === 0 || errorTimeout.rsaResponseRemainTime === 0) {
        if(!isAlreadyTimeout.current){
            isAlreadyTimeout.current = true
            enqueueSnackbar(
                intl.formatMessage({id: "app.page.serviceUnavailable.text"}), {
                    variant: "general",
                    mode: 'info',
                    persist: true, //it means it will never hide
                    onClose: event => navigate(-3),
                    TransitionProps: {
                        // timeout: {
                        //     exit: 0, // Sets the exit transition duration to 0 ms
                        // }
                    },
                })
        }

    }

    // Market Order: Make API call to confirm (accept) order (quote)
    /**
     * Confirm the FX order by sending a request to the FX API.
     *
     * @param {ConfirmFXOrderAPIRequestType} payload - The optional payload for confirming the FX order.
     * @returns {Promise<void>} - A promise that resolves once the confirmation is complete.
     */
    const confirmOrder = async (payload?: ConfirmFXOrderAPIRequestType) => {
        const formValidationErrors = validateOrderForm(fxOrderForm, currencies);
        if (formValidationErrors.length > 0) return;

        try {
            const response = await fxAPI.confirmOrder(payload ?? {orderId});
            if (
                !response.data || !response.data.data
            ) throw {
                errorMsg: MARKET_ORDER_CONFIRM_ORDER_FAIL,
                responseHttpStatus: response.status,
                responseData: response.data
            } as ErrorObjForToastBar
            switch (response.data.responseCode) {
                case FX_ORDER_CONFIRM_STATUS_CODE.SUCCESS:
                case FX_ORDER_CONFIRM_STATUS_CODE.SUCCESS_BUT_ET_GATEWAY_FAIL:
                    const formattedFXOrder = formatConfirmFXOrder(
                        response.data,
                        fxOrderForm as FXOrderFormRequiredType
                    );
                    openTransactionResultView(
                        FX_ORDER_TRANSACTION_ACKNOWLEDGEMENT_STATUS_TYPE.SUCCESS,
                        formattedFXOrder
                    );
                    dispatch(resetFXOrderForm()); // Reset FX order form for safe
                    timeoutForNoMsgForAck()
                    break;
                case FX_ORDER_CONFIRM_STATUS_CODE.RSA_REQUIRED:
                    const formattedFXOrderRSAData = formatConfirmFXOrder(
                        response.data,
                        fxOrderForm as FXOrderFormRequiredType
                    ) as FXOrderRSAType;
                    setChallengeQuestion(response.data.data.challengeQuestion);
                    requestRSA(formattedFXOrderRSAData);
                    break;
                default:
                    const responseMsg = response.data.responseMsg
                    const responseCode = response.data.responseCode
                    const rejectQuoteReason:string =`${REJECT_QUOTE_REJECT_BY_UI} ${responseCode} ${responseMsg}`
                    fxAPI.cancelOrder(currentOrPrevOrderId, rejectQuoteReason)
                    const formattedFailureFXOrder = formatConfirmFXOrder(
                        response.data,
                        fxOrderForm as FXOrderFormRequiredType
                    );
                    openTransactionResultView(
                        FX_ORDER_TRANSACTION_ACKNOWLEDGEMENT_STATUS_TYPE.FAIL,
                        formattedFailureFXOrder
                    );
                    timeoutForNoMsgForAck()
            }
        } catch (error:any) {
            // processApiError(error) this is deliberately commented, as we don't want to toastbar come out
            const responseMsg = error.responseData?.responseMsg
            const responseCode = error.responseData?.responseCode
            const rejectQuoteReason: string = `${REJECT_QUOTE_REJECT_BY_UI}${responseCode} ${responseMsg} ${!error.responseData ? `http${error.responseHttpStatus}` : ''}`;
            fxAPI.cancelOrder(currentOrPrevOrderId, rejectQuoteReason)
            openTransactionResultView(
                FX_ORDER_TRANSACTION_ACKNOWLEDGEMENT_STATUS_TYPE.FAIL,
                {
                    statusDescription: intl.formatMessage({
                        id: "app.page.transactionReceipt.statusDescription.fail.text",
                    }),
                    statusCode: FX_ORDER_TRANSACTION_ACKNOWLEDGEMENT_STATUS_CODE.FAIL,
                    // @ts-ignore no idea why it sudden give error
                    failureMessage: {
                        referenceId: "",
                        orderId,
                        "date&time": moment().format("YYYY-MM-DD hh:mmA"),
                    },
                    transactionStatus: FX_ORDER_TRANSACTION_ACKNOWLEDGEMENT_STATUS_TYPE.FAIL
                }
            );
            timeoutForNoMsgForAck()
        }
    };

    // Future Order: Make API call to create order
    /**
     * Creates an FX order using the provided payload.
     * @async
     * @param {ConfirmFXOrderAPIRequestTypeForFuture} payload - The payload for creating the FX order.
     */
    const createOrder = async (payload: ConfirmFXOrderAPIRequestTypeForFuture) => {
        try {
            const response: (AxiosResponse<CreateFXOrderAPIResponseType>) = await fxAPI.createOrder(
                formatCreateFutureOrderPayload(fxOrderForm, payload)
            );

            const alertString =
                `responseMsg: ${response.data.responseMsg}
responseCode: ${response.data.responseCode}
response.data: ${JSON.stringify(response.data.data, null, 2)}
`;
            // alert(alertString)
            if (!response.data || !response.data.data
            ) throw {
                errorMsg: FUTURE_ORDER_CREATE_ORDER_FAIL,
                responseHttpStatus: response.status,
                responseData: response.data
            } as ErrorObjForToastBar

            //console.log(response.data);
            switch (response.data.responseCode) {
                case FX_ORDER_CONFIRM_STATUS_CODE.SUCCESS:
                    const formattedFXOrder = formatCreateFutureOrder(
                        response.data as any,
                        fxOrderForm as FXOrderFormRequiredType
                    );
                    openTransactionResultView(
                        FX_ORDER_TRANSACTION_ACKNOWLEDGEMENT_STATUS_TYPE.SUCCESS,
                        formattedFXOrder
                    );
                    //this is probably wrong!!!
                    dispatch(resetFXOrderForm()); // Reset FX order form for safe
                    timeoutForNoMsgForAck()
                    break;
                case FX_ORDER_CONFIRM_STATUS_CODE.RSA_REQUIRED:
                    const formattedFXOrderRSAData = formatConfirmFXOrder(
                        response.data,
                        fxOrderForm as FXOrderFormRequiredType
                    ) as FXOrderRSAType;
                    setChallengeQuestion(response.data.data.challengeQuestion);
                    //this is probably wrong!!!
                    requestRSA(formattedFXOrderRSAData);
                    break;
                default:
                    const formattedFailureFXOrder = formatConfirmFXOrder(
                        response.data,
                        fxOrderForm as FXOrderFormRequiredType
                    );
                    openTransactionResultView(
                        FX_ORDER_TRANSACTION_ACKNOWLEDGEMENT_STATUS_TYPE.FAIL,
                        formattedFailureFXOrder
                    );
                    timeoutForNoMsgForAck()
            }
        } catch (error) {
            // processApiError(error)
            openTransactionResultView(
                FX_ORDER_TRANSACTION_ACKNOWLEDGEMENT_STATUS_TYPE.FAIL,
                {
                    statusDescription: intl.formatMessage({
                        id: "app.page.transactionReceipt.statusDescription.fail.text",
                    }),
                    statusCode: FX_ORDER_TRANSACTION_ACKNOWLEDGEMENT_STATUS_CODE.FAIL,
                    // @ts-ignore no idea why it sudden give error
                    failureMessage: {
                        referenceId: "",
                        orderId:"",
                        "date&time": moment().format("YYYY-MM-DD hh:mmA"),
                    },
                    transactionStatus: FX_ORDER_TRANSACTION_ACKNOWLEDGEMENT_STATUS_TYPE.FAIL
                }
            );
            timeoutForNoMsgForAck()
        }
    };

    /**
     * Place an FX order based on the given payload.
     *
     * @param {Object} payload - The payload for the FX order.
     */
    const placeOrder = (payload?: ConfirmFXOrderAPIRequestType) => {
        // @ts-ignore
        if (fxOrderForm.future) createOrder(payload);
        else {
            if (!orderId || orderId === "") emitErrorToApp();
            else confirmOrder(payload);
        }
    };

    /**
     * Sets a timeout for displaying a message when no acknowledgement is received.
     * @function timeoutForNoMsgForAck
     * @returns {void}
     */
    const timeoutForNoMsgForAck = () => {
        setTimeout(() => {
            enqueueSnackbar(
                `Session ended, click "X" to return back to the app`, {
                    variant: "general",
                    mode: 'info',
                    persist: true, //it means it will never hide
                    onClose: (event, reason:CloseReason) => {
                        if (reason === 'instructed') {
                            navigateToApp()
                        }
                    }
                })
        }, postMessageAckWaitingTime)
    }

    return (
        <Layout>
            <Background>
                <LoadingContainer>
                    <MaeLoading size="30%" centered/>
                    <LoadingMessage>
                        {intl.formatMessage({
                            id: "app.page.orderPlacement.message.loading",
                        })}
                    </LoadingMessage>
                </LoadingContainer>
            </Background>
        </Layout>
    );
};

export default OrderPlacePage;
