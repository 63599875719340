import axios, {Axios, AxiosError, AxiosInstance} from "axios";
import {
	getLocalStorage,
	getURLQueryValueByKey,
	setSessionStorage,
} from "./common.utils";

let token = getURLQueryValueByKey("token");

const apiAbortController = AbortController ? new AbortController() : {};

const api:AxiosInstance = axios.create({
	baseURL: process.env.REACT_APP_API_URL,
	headers: {
		Authorization: `Bearer ${token}`,
		//token: token || "",
	},
});

export const apiMockPostman = axios.create({
	// baseURL:"",
	headers: {
		Authorization: `Bearer ${token}`,
		//token: token || "",
	},
});

const marketAxiosInstance = axios.create({
	baseURL: process.env.REACT_APP_MARKET_API_URL,
});

const onRejected = (
	errors: AxiosError | string
): Promise<AxiosError | string> => {
	/*Sentry.setExtra("errors", errors);
	Sentry.captureException(errors);*/
	return Promise.reject(errors);
};

api.interceptors.response.use((response) => {
	if (response.data?.status == -1) {
		onRejected("API error status: -1");
	}
	return response;
}, onRejected);

marketAxiosInstance.interceptors.response.use((response) => {
	if (response.data?.status == -1) {
		onRejected("API error status: -1");
	}
	return response;
}, onRejected);

export const updateAPIAxiosInstanceToken = (newToken: string) => {
	const completeAuthorizationHeader:string = `Bearer ${newToken}`
	// @ts-ignore
	api.defaults.headers["Authorization"] = completeAuthorizationHeader
	// @ts-ignore
	api.defaults.headers.common["Authorization"] = completeAuthorizationHeader;
};

export const updateMarketAxiosInstanceToken = (
	token: string,
	expiration: number
) => {
	setSessionStorage("marketToken", token);
	// setSessionStorage("marketTokenExpiration", expiration * 60 * 100);
	marketAxiosInstance.defaults.headers.common[
		"Authorization"
	] = `Bearer ${token}`;
};

export { api, marketAxiosInstance };
