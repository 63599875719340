import {useEffect, useMemo, useState} from "react";
import {useIntl} from "react-intl";
import Helmet from "react-helmet";
import styled from "styled-components";
import {useSnackbar} from "notistack";
import Layout from "../components/common/Layout";
import Section from "../components/styled/sections/Section";
import SectionContent from "../components/styled/sections/SectionContent";
import SectionHeader from "../components/styled/sections/SectionHeader";
import SectionHeaderStartContainer from "../components/styled/sections/SectionHeaderStartContainer";
import PickerDrawer from "../components/common/PickerDrawer";
import BorderedSelect from "../components/styled/BorderedSelect";
import AccountCard from "../components/watchlist/AccountCard";
import WatchlistFXSection from "../components/watchlist/WatchlistFXSection";

import {SelectedPickerOptionType} from "../types/common.type";
import {
    ACTIVATED_ACCOUNT_ROLE,
    ALLOWED_STREAMING_ROLE,
    NO_ACCOUNT_ROLE,
} from "../constants/common.constant";
import {
    DEFAULT_INACTIVATED_ACCOUNT_RATE_UPDATE_INTERVAL,
} from "../constants/watchlist.constant";
import {useAppDispatch, useAppSelector} from "../utils/store.utils";
import {selectUser} from "../slice/user";
import {updateForexes} from "../slice/fx";
import {
    selectSelectedWatchlistIndex,
    selectWatchlist,
    updateSelectedWatchlistIndex,
    updateWatchlists,
    updateWatchlistFX,
} from "../slice/watchlist";
import useNavigateThrottle from "../hooks/useNavigateThrottle";
import {formatURL} from "../formatters/common";
import {
    formatFXObjectList,
    formatFXRawList,
} from "../formatters/fx/api/response";
import {formatWatchlist} from "../formatters/watchlist/api/resonpse";
import watchlistAPI from "../api/watchlist.api";
import fxAPI from "../api/fx.api";
import LoadingContainer from "../components/styled/LoadingContainer";
import LoadingSpinner from "../components/styled/LoadingSpinner";
import {cleanRET_APPandRET_DCCookie} from "../utils/cleanCookieRET_APPandRET_DCCookie";
import {selectWlUpdateInterval, setWlUpdateInterval} from "../slice/app";

const WatchlistSelect = styled(BorderedSelect)`
    width: 100%;
    margin-top: 16px;
    margin-bottom: 40px;
`;

const WatchlistPage = () => {
    const navigate = useNavigateThrottle();
    const intl = useIntl();
    const user = useAppSelector(selectUser);
    const watchlists = useAppSelector(selectWatchlist);
    const selectedWatchlistIndex = useAppSelector(selectSelectedWatchlistIndex);
    const dispatch = useAppDispatch();
    const {enqueueSnackbar} = useSnackbar();
    const [isFirstLoading, setIsFirstLoading] = useState<boolean>(true);
    const [rateLastUpdateTime, setRateLastUpdateTime] = useState<
        number | undefined
    >();
    const errorTimeout = useAppSelector((state) => state.errorTimeout);
    const [isWatchlistDrawerOpen, setIsWatchlistDrawerOpen] =
        useState<boolean>(false);
    //const isInitialized = useRef<boolean>(false);
    const intervalTimeForActivated = useAppSelector(selectWlUpdateInterval);
    const intervalTimeForInactivated = useMemo(() => {
        // if (ALLOWED_STREAMING_ROLE.indexOf(user.type) > -1) {
        // 	return isNaN(parseInt(process.env.REACT_APP_RATE_UPDATE_INTERVAL))
        // 		? DEFAULT_RATE_UPDATE_INTERVAL
        // 		: parseInt(process.env.REACT_APP_RATE_UPDATE_INTERVAL);
        // } else {
        return isNaN(
            parseInt(process.env.REACT_APP_INACTIVATED_ACCOUNT_RATE_UPDATE_INTERVAL)
        )
            ? DEFAULT_INACTIVATED_ACCOUNT_RATE_UPDATE_INTERVAL
            : parseInt(
                process.env.REACT_APP_INACTIVATED_ACCOUNT_RATE_UPDATE_INTERVAL
            );
        // }
    }, []);
    const intervalTime = ALLOWED_STREAMING_ROLE.indexOf(user.type) > -1 ? intervalTimeForActivated : intervalTimeForInactivated

    //console.log(intervalTime, isInitialized.current, isFirstLoading);

    useEffect(() => {
        cleanRET_APPandRET_DCCookie()
        fetchWatchlist();
    }, []);

    useEffect(() => {
        let interval: ReturnType<typeof setInterval>;
        interval = setInterval(() => {
            fetchRate();
        }, intervalTime);

        return () => clearInterval(interval);
    }, [watchlists, selectedWatchlistIndex]);

    /**
     * Fetches the user's watchlist from the backend API.
     * Sets the first loading state to true and updates the watchlist.
     * If successful, updates the rate last update time and dispatches the updated forex and watchlist data.
     * If there is an error, logs the error and displays a snackbar message.
     * Finally, sets the first loading state to false.
     */
    const fetchWatchlist = () => {
        //if (!isInitialized.current) setIsFirstLoading(true);
        setIsFirstLoading(true);
        watchlistAPI
            .get()
            .then((response) => {
                //console.log(response.data);
                setRateLastUpdateTime(response.data.lastUpdate);
                dispatch(updateForexes(formatFXRawList(response.data.fxlist)));
                dispatch(updateWatchlists(formatWatchlist(response.data.watchlist)));
            })
            .catch((error) => {
                console.error(error);
                enqueueSnackbar(
                    intl.formatMessage({
                        id: "app.page.watchlist.snackbar.getWatchlist.message.error",
                    }),
                    {variant: "general", mode: "info"}
                );
            })
            .finally(() => {
                setIsFirstLoading(false);
                //if (!isInitialized.current) isInitialized.current = true;
            });
    };

    /**
     * Fetches the latest exchange rates for the selected watchlist.
     * If there are no watchlists or the selected watchlist has no currency pairs,
     * no action will be taken.
     *
     * @returns {void}
     */

    if (errorTimeout.passwordResponseRemainTime === 0 || errorTimeout.rsaResponseRemainTime === 0) {
        enqueueSnackbar(
            intl.formatMessage({id: "app.page.serviceUnavailable.text"}), {
                variant: "general",
                mode: 'info',
                persist: true, //it means it will never hide
                onClose: event => () => {
                }, //do nothing
                TransitionProps: {
                    // timeout: {
                    //     exit: 0, // Sets the exit transition duration to 0 ms
                    // }
                },
            })
    }
    const fetchRate = () => {
        if (
            !watchlists ||
            watchlists.length < 1 ||
            watchlists[selectedWatchlistIndex].fxList.length < 1
        )
            return;

        fxAPI
            .getRate(watchlists[selectedWatchlistIndex].fxList.map((v) => v.symbol))
            .then((response) => {
                //console.log(response.data);
                setRateLastUpdateTime(response.data.lastUpdate);
                dispatch(
                    updateWatchlistFX({
                        selectedWatchlistIndex,
                        fxList: formatFXObjectList(response.data.data),
                    })
                );
            })
            .catch((error) => {
                console.error(error);
                enqueueSnackbar(
                    intl.formatMessage({
                        id: "app.page.watchlist.snackbar.getRate.message.error",
                    }),
                    {variant: "general", mode: "info"}
                );
            });
    };
    /**
     * Handles the action to close the drawer.
     *
     * @param {React.KeyboardEvent | React.MouseEvent} event - The event triggered by the user.
     *
     * @returns {void}
     */



    const handleDrawerClose = (event: React.KeyboardEvent | React.MouseEvent) => {
        if (
            event.type === "keydown" &&
            ((event as React.KeyboardEvent).key === "Tab" ||
                (event as React.KeyboardEvent).key === "Shift")
        ) {
            return;
        }

        setIsWatchlistDrawerOpen(false);
    };

    /**
     * Handles the selection of a watchlist option.
     *
     * @param {SelectedPickerOptionType} selectedPickerOption - The selected picker option.
     */
    const handleWatchlistPicked = (
        selectedPickerOption: SelectedPickerOptionType
    ) => {
        dispatch(updateSelectedWatchlistIndex(selectedPickerOption.watchlist));
    };

    return (
        <Layout
            title={intl.formatMessage({id: "app.page.watchlist.header.title"})}
        >
            <Helmet>
                <title>
                    {intl.formatMessage({
                        id: "app.page.watchlist.header.title",
                    })}
                </title>
            </Helmet>
            {isFirstLoading ? (
                <LoadingContainer>
                    <LoadingSpinner display="block" $centered/>
                </LoadingContainer>
            ) : (
                <>
                    {ACTIVATED_ACCOUNT_ROLE.indexOf(user.type) > -1 && (
                        <Section>
                            <SectionContent hasPaddingX>
                                <WatchlistSelect
                                    onClick={(e) => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        setIsWatchlistDrawerOpen(true);
                                    }}
                                >
                                    {watchlists &&
                                        watchlists.length > 0 &&
                                        watchlists[selectedWatchlistIndex] &&
                                        watchlists[selectedWatchlistIndex].name}
                                </WatchlistSelect>
                            </SectionContent>
                        </Section>
                    )}
                    <WatchlistFXSection rateLastUpdateTime={rateLastUpdateTime}/>
                </>
            )}
            {NO_ACCOUNT_ROLE.indexOf(user.type) > -1 && (
                <Section hasMarginBottom>
                    <SectionHeader>
                        <SectionHeaderStartContainer>
                            {intl.formatMessage({
                                id: "app.page.watchlist.section.account.title",
                            })}
                        </SectionHeaderStartContainer>
                    </SectionHeader>
                    <SectionContent hasPaddingX>
                        <AccountCard
                            onClick={() => navigate(formatURL("/account/product"))}
                        />
                    </SectionContent>
                </Section>
            )}
            <PickerDrawer
                open={isWatchlistDrawerOpen}
                options={{
                    watchlist: watchlists.map((v) => ({label: v.name, value: v.id})),
                }}
                selecting={selectedWatchlistIndex}
                onClose={handleDrawerClose}
                onPick={handleWatchlistPicked}
            />
        </Layout>
    );
};

export default WatchlistPage;
